import(/* webpackMode: "eager" */ "/vercel/path0/components/Banner/Magnifier/Magnifier.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Banner/Tags/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Carousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ParallaxLeave/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Screen/Hero/Quote.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Screen/Presentation/Content/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ScrollHint/ScrollHint.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Slot/Track/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/SwipeHint/SwipeHint.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.2/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/dji-mini4-pro.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/dji-rs3.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Floe-tournage-detouree.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/fuji-xs10.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/hero.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/landing-1.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/landing-2.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/landing-3.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/landing-4.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/landing-5.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo-side.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/presentation-audiovisuel.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/presentation-moi.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/presentation-montage.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/presentation-photo.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/presentation-tournage.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/zoom-h5.webp");
