"use client";

import ButtonLink from "components/Button/Link";
import Logo from "components/Logo";
import QuoteBadge from "components/Quote/Badge";
import QuoteItem from "components/Quote/Item";
import QuoteSlide from "components/Quote/Slide";
import QuoteSlider from "components/Quote/Slider";
import SlotTrack from "components/Slot/Track";
import SwipeHintSlider from "components/SwipeHint/Slider";
import H2 from "components/Typography/H2";
import H3 from "components/Typography/H3";
import P from "components/Typography/P";
import SpanUnderline from "components/Typography/SpanUnderline";
import { PORTFOLIO, ZCAL_FIRST, ZCAL_INTERVIEW, ZCAL_MONTAGE, ZCAL_PHOTO } from "constants/links";
import cn from "helpers/cn";
import logoSide from "public/logo-side.svg";
const QuoteHero = () => <div className={cn("flex flex-col items-center", "mx-5 lg:mx-10", "gap-16 md:gap-24 lg:gap-24")} data-sentry-component="QuoteHero" data-sentry-source-file="Quote.tsx">
		<H2 className="relative" data-sentry-element="H2" data-sentry-source-file="Quote.tsx">
			Mes <SpanUnderline variant="secondary" data-sentry-element="SpanUnderline" data-sentry-source-file="Quote.tsx">prestations</SpanUnderline>
			<Logo className="absolute left-full top-0 h-sideLogo w-auto -rotate-90 -scale-x-100" alt="" src={logoSide} data-sentry-element="Logo" data-sentry-source-file="Quote.tsx" />
		</H2>
		<div className="flex w-full flex-col items-center gap-10">
			<div className="flex w-full flex-col items-center">
				<div className="flex w-full justify-start overflow-auto px-5">
					<QuoteSlider data-sentry-element="QuoteSlider" data-sentry-source-file="Quote.tsx">
						<QuoteSlide data-sentry-element="QuoteSlide" data-sentry-source-file="Quote.tsx">
							<QuoteItem title={<H3>Montage vidéo</H3>} cta={<SlotTrack name="click quote button montage">
										<ButtonLink variant="outlined" href={ZCAL_MONTAGE}>
											Je prends
											<br />
											rendez-vous
										</ButtonLink>
									</SlotTrack>} perks={["Solo ou renfort d'équipe", "Bibliothèque FX / SFX", "Sous-titrage", "Colorimétrie", "Allers-retours illimités", "Télétravail total", "Mobilité France, Europe"]} data-sentry-element="QuoteItem" data-sentry-source-file="Quote.tsx">
								<SwipeHintSlider data-sentry-element="SwipeHintSlider" data-sentry-source-file="Quote.tsx" />
							</QuoteItem>
						</QuoteSlide>
						<QuoteSlide data-sentry-element="QuoteSlide" data-sentry-source-file="Quote.tsx">
							<QuoteBadge title="Populaire" data-sentry-element="QuoteBadge" data-sentry-source-file="Quote.tsx">
								<QuoteItem title={<H3>Interview / Reportage</H3>} cta={<SlotTrack name="click quote button interview">
											<ButtonLink variant="outlined" href={ZCAL_INTERVIEW}>
												Je prends
												<br />
												rendez-vous
											</ButtonLink>
										</SlotTrack>} perks={["Organisation du tournage", "Réalisation sur place", "Captation 9:16, 16:9", "Prises de vue aériennes", "Montage complet", "Allers-retours illimités", "Mobilité France, Europe"]} data-sentry-element="QuoteItem" data-sentry-source-file="Quote.tsx" />
							</QuoteBadge>
						</QuoteSlide>
						<QuoteSlide data-sentry-element="QuoteSlide" data-sentry-source-file="Quote.tsx">
							<QuoteItem title={<H3>Photo</H3>} cta={<SlotTrack name="click quote button photo">
										<ButtonLink variant="outlined" href={ZCAL_PHOTO}>
											Je prends
											<br />
											rendez-vous
										</ButtonLink>
									</SlotTrack>} perks={["Analyse du besoin", "Shooting inté / exté", "Portrait, paysage, voyage", "Prises de vue aériennes", "Retouche", "Mobilité France, Europe", <SlotTrack key="sell photo" name="click quote link sell photo">
										<ButtonLink href={PORTFOLIO}>
											Vente d&apos;images
										</ButtonLink>
									</SlotTrack>]} data-sentry-element="QuoteItem" data-sentry-source-file="Quote.tsx" />
						</QuoteSlide>
					</QuoteSlider>
				</div>
				<div className="flex flex-col items-center gap-8">
					<P className="text-center" data-sentry-element="P" data-sentry-source-file="Quote.tsx">
						Pour une prestation sur-mesure, je t&apos;invite à prendre
						rendez-vous avec moi !
					</P>
					<SlotTrack name="click quote button first" data-sentry-element="SlotTrack" data-sentry-source-file="Quote.tsx">
						<ButtonLink variant="contained" href={ZCAL_FIRST} data-sentry-element="ButtonLink" data-sentry-source-file="Quote.tsx">
							Je prends rendez-vous
						</ButtonLink>
					</SlotTrack>
				</div>
			</div>
		</div>
	</div>;
export default QuoteHero;